import React from 'react'
import { graphql } from 'gatsby'

import Layout from 'layouts/Base'
import SEO from 'components/shared/SEO'
import HeaderImage from 'components/HeaderImage'
import RoomPage from 'components/RoomPage'
import { formatNodes } from 'utils/formatNodes'

function BalconyPage({data}) {
	const recommended = formatNodes(data.recommendedQuery)
  const lightData = formatNodes(data.lightQuery)
  const shadeData = formatNodes(data.shadeQuery)

  const pageData = {
    header: {
      title: "Plants for the Balcony",
      copy: "Every view can be improved with the right assortment of plants.",
      image: data.balcony.childImageSharp.fluid
    },
    recommended: {
      title: "Top Picks",
      cta: {
        title: "View all balcony plants",
        link: "/plants?balcony"
      },
      items: recommended.slice(0,8)
    },
    sections: [
      {
        title: "Plants that love light",
        image: data.lightBalcony.childImageSharp.fluid,
        items: lightData
      },
      {
        title: "Plants that do well in the shade",
        image: data.porch.childImageSharp.fluid,
        items: shadeData
      }
    ]
  }
  return <RoomPage data={pageData}/>
}

export default ({ data, location }) => {
  return (
    <>
      <SEO title="Plants for your balcony"/>
      <BalconyPage data={data}/>
    </>
  )
}

export const query = graphql`
  query BalconyQuery {
    lightBalcony: file(relativePath: { eq: "patio/light-balcony.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    balcony: file(relativePath: { eq: "patio/balcony.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    porch: file(relativePath: { eq: "patio/porch.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    recommendedQuery: allStripeSku(
      limit: 15,
      filter: { metadata: { balcony: { in: "True" } } }, 
      sort: { fields: [metadata___ranking], order: ASC  }
    )
     {
      group(field: product___id) {
        edges {
          node {
            ...Sku
          }
        }
      }
    }
    lightQuery: allStripeSku(
      limit: 15,
      filter: { metadata: { b_sun: { in: "True" } } },
      sort: { fields: [metadata___ranking], order: ASC  }
    ) {
      group(field: product___id) {
        edges {
          node {
            ...Sku
          }
        }
      }
    }
    shadeQuery: allStripeSku(
      limit: 15,
      filter: { metadata: { b_shade: { in: "True" } } },
      sort: { fields: [metadata___ranking], order: ASC  }
    ) {
      group(field: product___id) {
        edges {
          node {
           ...Sku
          }
        }
      }
    }
  }
`